import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import MyToast from './MyToast';
import { format_date_nohour, userHasAtLeast } from "./Utils";
import { useState } from "react";

const Header = function (props) {

    const { t } = useTranslation()

    const isLogged = Boolean(props.user?.email);

    let current_username = "No_name"
    let current_dateFin = ""

    const [showMenu, setShowMenu] = useState(false);

    const hide_or_show_menu = function () {
        setShowMenu(!showMenu);
    }

    const GetLabel = function ({ text }) {

        return <li className="text-secondary fs-3 border-bottom border-secondary m-2" style={{ marginTop: "20px" }}><label>{text}</label></li>
    }

    const GetLink = function ({ href, text }) {

        return <li><a className="nav-link active pe-3" href={href}>{text}</a></li>
    }

    if (props.user?.username) current_username = props.user.username.substring(0, 15)
    if (props.user?.dateFin) current_dateFin = " (" + format_date_nohour(props.user.dateFin) + ")"

    return <div className="header fs-5 sticky-top">

        <nav className="navbar .navbar-expand navbar-light bg-light p-2">

            <div className="col-5 m-2">

                <a className="navbar-brand" href="/">
                    <img style={{ height: "2.5rem", width: "auto" }} src="/images/logo_digibobo1.png" alt="Logo Digibobo" />
                </a>
                {showMenu && <LanguageSelector />}
            </div>


            <div className="text-end" style={{ width: "50%" }}>

                <button
                    type="button"
                    className="btn btn-lg border-secondary fs-1"
                    onClick={hide_or_show_menu}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {showMenu &&
                    <ul className="navbar-nav me-auto mb-2 mb-md-0 fs-2">

                        <GetLabel text={"Menu"} />

                        {!isLogged &&
                            <li><a className="nav-link link-primary pe-3" href="/login">Login</a></li>
                        }
                        {isLogged &&
                            <li><a className="nav-link link-success text pe-3" href="/logout">{"Logout - " + current_username + current_dateFin}</a></li>
                        }

                        <table>
                            <tbody>
                                <tr>
                                    <td className="align-top">

                                        <GetLink href="/breebar_func" text="BreeBar" />
                                        <GetLink href="/shop_pc_composer" text={t("header.pc")} />
                                        <GetLink href="/shop_main" text={t("header.shop")} />

                                    </td>

                                    {isLogged &&

                                        <td>
                                            {userHasAtLeast(props.user, true) &&
                                                <GetLink href="/activity" text={t("header.activity")} />
                                            }

                                            <GetLink href="/settings" text={t("header.settings")} />

                                            {userHasAtLeast(props.user, false) &&
                                                <GetLink href="/pending" text={t("header.pending")} />
                                            }
                                        </td>

                                    }
                                </tr>
                            </tbody>
                        </table>

                    </ul>
                }

            </div>


        </nav >

        <MyToast />
    </div >
}

export default Header;