
import { useTranslation } from "react-i18next";
import { OrderForm, digitGroup } from "../Utils";
import Footer from "../Footer";
import Header from "../Header";

const BreebarPrice = function (props) {

    const { t } = useTranslation();

    const Card = function ({ title, price, items, footer }) {

        return (

            <div className="card text-center border-primary" style={{ margin:"30px", fontSize: "1.5em" }}>

                <div className="card-header border-primary">
                    <h3 style={{ marginTop: "20px" }}>{title}</h3>
                </div>

                <div className="card-body">

                    <h3 className="card-title fs-2">{digitGroup(price)}<small> FCFA</small></h3>

                    <ul className="list-group list-group-flush text-left">
                    <li className="list-group-item card-title fs-2">({t("bb.about")} ${(price / 650).toFixed()})</li>

                        {items.map((item, idx) => (<li key={idx} className="list-group-item">
                            {item}
                        </li>))}

                    </ul>

                </div >
                <div className="card-footer fs-3">
                    <ul className="list-unstyled text-left">
                        {footer.map((f, idx) => {
                            return <li key={idx}>{f}</li>
                        })}
                    </ul>

                    <div className="col-8 mx-auto">

                        <OrderForm total={price} content={{ title: title + " Breebar" }} />
                    </div>
                </div>
            </div >

        )
    }

    const HeaderAndFooterText = () => {
        return <p className="fs-3 text-center lh-lg" style={{margin:"30px"}}>{t("bb.price0")} <a className="btn btn-outline-primary fs-3" href="/breebar_func">{t("bb.func_title")}</a></p>
    }

    const TitreSection = function ({ titre }) {
        return <div 
        className="display-5" 
        style={{ backgroundColor: "bisque", padding: "20px", marginTop: "20px", textAlign: "center" }}
        >
            {titre}
            </div>
    }



    return <div>
        <Header user={props.user} />

        <div className="fs-5">

            <h1 className="text-center" style={{marginTop:"40px"}}>{t("bb.price_title")}</h1>

            <HeaderAndFooterText />

            <TitreSection titre={t("bb.soft0")} />
            <div className="row row-cols-1 row-cols-lg-2 p-2 m-2 g-4">

                <div className="col">
                    <Card
                        title={t("bb.soft1")}
                        price={65000}
                        items={[
                            t("bb.soft2"),
                            <span>{t("bb.soft3")} <sup>1</sup></span>,
                            t("bb.soft4"),
                            <span>{t("bb.soft5")} <sup>2</sup></span>,
                        ]}
                        footer={[
                            "1: " + t("bb.soft6"),
                            "2: " + t("bb.soft7"),
                        ]}
                    />
                </div>

                <div className="col">
                    <Card
                        title={t("bb.kit0")}
                        price={260000}
                        items={[
                            t("bb.kit1"),
                            t("bb.kit2"),
                            t("bb.kit3"),
                            t("bb.kit4"),
                            t("bb.kit5"),
                            t("bb.kit6")
                        ]}
                        footer={[
                            t("bb.kit7"),
                        ]}
                    />
                </div>
            </div>

            <TitreSection titre={t("bb.assist0")} />
            <div className="row row-cols-1 row-cols-lg-2 p-2 m-2 g-4">
                <div className="col">

                    <Card
                        title={t("bb.assist1")}
                        price={5000}
                        items={[
                            t("bb.assist2"),
                        ]}
                        footer={[
                            t("bb.assist3"),
                            t("bb.assist4"),
                        ]}
                    />
                </div>

                <div className="col">

                    <Card
                        title={t("bb.assist5")}
                        price={5000}
                        items={[
                            t("bb.assist6"),
                        ]}
                        footer={[
                            t("bb.assist7"),
                            t("bb.assist8"),
                            t("bb.assist9"),
                        ]}
                    />

                </div>
            </div>

            <TitreSection titre={t("bb.cloud0")} />
            <div className="row row-cols-1 row-cols-lg-2 p-2 m-2 g-4">

                <div className="col">

                    <Card
                        title={t("bb.cloud1")}
                        price={10000}
                        items={[
                            t("bb.soft6"),
                            t("bb.cloud2"),
                        ]}
                        footer={[
                            t("bb.cloud3"),
                            t("bb.cloud4"),
                            t("bb.cloud5"),
                        ]}
                    />
                </div>
                <div className="col">

                    <Card
                        title={t("bb.cloud6")}
                        price={10000}
                        items={[
                            t("bb.cloud7"),
                            t("bb.cloud8"),
                        ]}
                        footer={[
                            t("bb.cloud4"),
                            t("bb.cloud9"),
                        ]}
                    />
                </div>
            </div>

            <HeaderAndFooterText />

        </div>

        <Footer />

    </div>
}

export default BreebarPrice;