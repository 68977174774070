import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NbItems, digitGroup, format_date } from "../Utils";
import Header from "../Header";
import PaginatedItems from "../Paginator";
import { MyInputSearch } from "../FormElements";
const proxyUrl = require("../../../package.json").proxy;

const OrderAdmin = function (props) {

    const { t } = useTranslation();

    const [confirmTreated, setConfirmTreated] = useState(false);

    const [orders, setOrders] = useState([]);
    const [ordersFilt, setOrdersFilt] = useState([]);

    const [order_id, setOrder_id] = useState(0); // pour savoir quel élément supprimer

    // fetch Orders
    useEffect(() => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + '/orders');
                const data = await response.json();
                setOrders(data);
                setOrdersFilt(data);
            } catch (error) {
                console.error(error);
            }
        })();

    }, [])


    const markOrderAsTreated = async () => {

        (async () => {
            try {
                const response = await fetch(proxyUrl + "/order_treated/" + order_id)

                if (response.status === 201) window.location.reload()

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmTreated(false)
    }

    const treatedOrder_onclick = (id) => {
        setOrder_id(id)
        setConfirmTreated(!confirmTreated)
    }

    const RenderOrders = () => {

        const [currentItems, setCurrentItems] = useState([]);

        const table_titles = [
            "id",
            "Phone",
            "Total",
            "Date",
            "Treated",
            "content",
            "Options"
        ]

        return <>
            <PaginatedItems itemsPerPage={10} items={ordersFilt} setCurrentItems={setCurrentItems} />

            <table className="table table-hover">
                <thead>
                    <tr>
                        {table_titles.map((title_elt, i) => (
                            <th key={i} scope="col">{title_elt}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={ordersFilt.length} />
                        </td>
                    </tr>
                    {currentItems.map((order_elt) => {

                        const order_content = JSON.stringify(order_elt.content)

                        return <tr key={order_elt.id}>
                            <th scope="row">{order_elt.id}</th>
                            <td>{order_elt.phone}</td>
                            <td>{digitGroup(order_elt.total)}</td>
                            <td>{format_date(Date.parse(order_elt.dateHeure.toString()))}</td>
                            <td>
                                {order_elt.treated ? <span style={{ color: "green" }}>ON</span> : <span style={{ color: "brown" }}>Off</span>}
                                {!order_elt.treated &&
                                    <>
                                        <button className="btn" onClick={() => treatedOrder_onclick(order_elt.idOrder)}>
                                            <img style={{ height: "30px", width: "auto" }} src="/images/select.svg" alt="Corbeille" />
                                        </button>
                                        {(confirmTreated && (order_elt.idOrder === order_id)) && <button className="btn btn-danger" type="button" onClick={markOrderAsTreated}>Confirm Treated</button>}
                                    </>
                                }

                            </td>
                            <td>{order_content.substring(0, 60) + ((order_content.length > 60) ? " [...]" : "")}</td>

                            <td>
                                <a className="btn" href={"/order/" + order_elt.idOrder}>
                                    <img style={{ height: "30px", width: "auto" }} src="/images/pencil.svg" alt="Crayon" />
                                </a>
                            </td>
                        </tr>
                    }
                    )}
                </tbody>
            </table>
        </>
    }

    return <div>

        <Header user={props.user} />

        <div className="body fs-5">

            <a className="my-3 fs-4 btn btn-lg btn-outline-secondary align-middle" href="/settings">
                {t("settings.back")}
            </a>

            <h1>{t("shop.orders")}</h1>

            <MyInputSearch
                myLabel="Rechercher une Commande"
                idGroupe={100}
                items={orders}
                setItemsFiltered={setOrdersFilt}
                filt1="idOrder"
                filt2="total"
                filt3="phone"
            />

            <RenderOrders />
        </div>

    </div>
}

export default OrderAdmin;