import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PaginatedItems from "../Paginator";
import { displaySnackbar, format_date_nohour as format_date_no_hour, liste_priv, NbItems, slang, userHasPriv } from "../Utils";
import { MyInputSearch } from "../FormElements";
const proxyUrl = require("../../../package.json").proxy;

// Liste des Utilisateurs avec les dates de Fin d'accès

export default function ListeUsersAccesCloud({ current_user, users, refreshUser, setRefreshUser }) {

    const { t } = useTranslation()

    const [currentItems, setCurrentItems] = useState([]);

    const [selected_user, setSelected_user] = useState(0);

    const [users_filtered, setUsers_Filtered] = useState([]);

    const [dateFinAccess, setDateFinAccess] = useState(0);

    const [confirmValidation, setConfirmValidation] = useState(false);

    useEffect(() => {

        setUsers_Filtered(users)
    }, [users]);

    // click on Save Date Button
    const SaveDate = (id) => {

        (async () => {

            try {

                const response = await fetch(proxyUrl + "/user_date", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: id, date: dateFinAccess }),
                })

                if (response.status === 201) {

                    displaySnackbar("User " + id + " : " + slang("Date enregistrée !", "Date saved !"), slang("Succès", "Success"), "warning")

                    setRefreshUser(!refreshUser)
                    setSelected_user(0)
                }

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmValidation(false)
    }

    // click on Validation Button
    const ValidateElement = (id) => {

        (async () => {

            try {

                const response = await fetch(proxyUrl + "/user_validation", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({ id: id }),
                })

                if (response.status === 201) {

                    displaySnackbar("User " + id + " : " + slang("Date validée !", "Date validated !"), slang("Succès", "Success"), "warning")

                    setRefreshUser(!refreshUser)
                    setSelected_user(0)
                }

            } catch (error) {
                console.error(error);
            }
        })();

        setConfirmValidation(false)
    }

    const validateElement_onclick = (id) => {

        setSelected_user((selected_user === id) ? 0 : id)
        setConfirmValidation(!confirmValidation)
    }

    const selectLic = async (id) => {

        setSelected_user(id)
        if (dateFinAccess === 0) return

        try {
            const url = proxyUrl + '/user_date'

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    id: id,
                    date: dateFinAccess,
                }),
            })

            if (response.status === 201) {
                displaySnackbar(slang("Date de fin d'accès utilisateur enregistrée", "User access date saved"), slang("Succès", "success"), "success")

                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }

        } catch (error) {
            console.error(error);
        }
    }

    const ListeOptions = function ({ user }) {

        const id = user.id

        let show_pencil = false

        if (userHasPriv(user, liste_priv.digibobo.admin)) {

            // Seul un admin peut modifier la date d'un admin

            show_pencil = userHasPriv(current_user, liste_priv.digibobo.admin)
        } else {

            show_pencil = true
        }

        return <td>
            {((id === selected_user) && (!confirmValidation)) && <form onSubmit={e => e.preventDefault()}>
                <input type="date" className="form-input" onChange={(e) => { setDateFinAccess(e.target.value) }} value={dateFinAccess} />

                <button type="submit" className="btn btn-lg m-1 btn-warning" onClick={(e) => SaveDate(id)}>Save</button>
            </form>}

            {show_pencil &&

                <button className="btn" onClick={() => selectLic(id)}>
                    <img style={{ height: "40px", width: "auto" }} src="/images/pencil.svg" alt="Pencil" />
                </button>
            }

            {(userHasPriv(current_user, liste_priv.digibobo.admin) && (!user.validation)) && <>
                <button className="btn" onClick={() => validateElement_onclick(id)}>
                    <img style={{ height: "40px", width: "auto" }} src="/images/validation.svg" alt="Validation" />
                </button>
                {(confirmValidation && (id === selected_user)) &&
                    <button className="btn btn-danger" type="button" onClick={(e) => { ValidateElement(id) }}>{t("general.confirm")} Validation</button>
                }
            </>
            }
        </td>
    }

    const table_titles = [
        "id",
        "Mail",
        "Username",
        "Phone",
        "Date",
        "Validation",
        "Options"
    ]

    return <>
        <PaginatedItems itemsPerPage={5} items={users_filtered} setCurrentItems={setCurrentItems} />
        
        < div className="col-12 col-md-8 input-group mb-1 fs-6">
            <MyInputSearch
                myLabel={t("User")}
                idGroupe={15}
                items={users}
                setItemsFiltered={setUsers_Filtered}
                filt1="id"
                filt2="username"
                filt3="email"
            />
        </div>
        <table className="table table-hover">
            <thead>
                <tr>
                    {table_titles.map((title_elt, i) => (
                        <th key={i} scope="col">{title_elt}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
            <tr>
                        <td colSpan={table_titles.length} >

                            <NbItems count={currentItems.length} total={users_filtered.length} />
                        </td>
                    </tr>
                {currentItems.map((user_elt) => (
                    <tr key={user_elt.id}>
                        <th scope="row">{user_elt.id}</th>
                        <td>{user_elt.email}</td>
                        <td>{user_elt.username}</td>
                        <td>{user_elt.phone}</td>
                        <td>{format_date_no_hour(user_elt.dateFin)}</td>
                        <td>{user_elt.validation ? "True" : "False"}</td>

                        <ListeOptions user={user_elt} />
                    </tr>
                ))}
            </tbody>
        </table>
    </>
}